<template>
  <v-row class="mt-5 mb-5">
    <v-col cols="11"
           sm="2"
           class="d-flex justify-center">
      <v-icon
          class="cor-voltar"
          @click="subMonth()"
      >mdi-chevron-left
      </v-icon>
    </v-col>
    <v-col
        cols="11"
        sm="8"
    >
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              :value="dataFormatada"
              style="font-size: 25px"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            type="month"
            locale="pt"
            no-title
            scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="menu = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="11"
           sm="2"
           class="d-flex justify-center">
      <v-icon @click="addMonth" class="cor-voltar">mdi-chevron-right</v-icon>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DefaultDateInput',
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
  }),
  methods: {
    subMonth() {
      this.date = moment(this.date).subtract(1, 'months').format('YYYY-MM')
    },
    addMonth() {
      this.date = moment(this.date).add(1, 'months').format('YYYY-MM')
    },
  },
  computed: {
    dataFormatada() {
      moment.locale('pt-br')
      return this.date ? moment(this.date).format('MMMM YYYY') : ''
    },
  }
}
</script>

<style>
.cor-voltar {
  color: rgba(0, 0, 0, 0.54) !important;
}

.cor-voltar:hover {
  color: black !important;
}


</style>
